import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import React, { useState } from "react"
import { auth, db } from "../../../firebase-config"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_LOCAL } from "../../settings/project-settings"
import { createGlobalStyle } from "styled-components"
import { LineDividerSpacer } from "../../locust/components/LineDivider"
import Y from "../../locust/composables/Y"
import { GridRow } from "../dashboard"
import { getFontBebasNeue } from "../../assets/fonts/FontBebasNeue"
import { saveTempDataFBPortal_TEMP_00, getTempDataFBPortal_TEMP_00 } from "../../../firebase/firebase-portal-temp-00"
import { callEndpoint } from "../../service/endpoints-service"

export const GlobalStyle = createGlobalStyle`
*{
  margin:0;
  padding:0;
}
html, body {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    background-color : whitesmoke;
  }
  body {
    position: relative;
    width: 100%;
  }
  img{
    max-width:100%;
 }
`;


export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")

    const [read, setRead] = React.useState("-")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })


    
    return (
        <>
            <GlobalStyle/>

            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin >
                Login
            </QuickLogin>

            <TestBox title="Run Local Hello World Test" onClick={ async ( setResponse : any )=>{
                // firebase emulators:start --only functions
                

                const response = await callEndpoint({ 
                    key : 'none', 
                    token : token,
                    endpoint : ENDPOINT_LOCAL,
                })

                if( ENABLE_ERROR_LOGGING ) console.log(response)
                    //const testData : any = { "test data" : true  }
                    //const response = await saveTempDataFBPortal_TEMP_00( getUIDMain(), testData )

                    
                    // setResponse(<>
                        
                    //     <TestBoxLine>
                    //         {`uid: ${getUIDMain()}`}
                    //     </TestBoxLine>
                        
                    //     <TestBoxLine>
                    //         {`response: ${JSON.stringify(response)}`}   
                    //     </TestBoxLine>

                    //     <TestBoxLine>
                    //         {`response: ${JSON.stringify(testData)}`}   
                    //     </TestBoxLine>
                
                    // </>)

            }}/>
    

        </>
    )
}


const TestBoxLine = ( props : { children ?: any }) => <div>
    <div style={{ paddingTop : 10, minHeight : 50, width : 'calc(100% - 20px)', textAlign : 'left',paddingLeft : 10, paddingRight : 10 }}>{props.children} </div>
    <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
</div>

const TestBox = ( props : { 
    title ?: string, 
    children ?: any, 
    onClick : ( setReponse:(value:string)=>void ) => any  
}) => {
    const [ response, setReponse ] = useState("")
    return (
        <>
            <div style={{ width : '100%', textAlign : 'center', marginTop : 15, marginBottom : 15}}>
                <div style={{ display : 'inline-block', width : '100%', borderRadius : 10, maxWidth : 800, minWidth : 350-20, paddingLeft : 10, paddingRight : 10, backgroundColor: 'white' , minHeight : 100}}>
                    <div style={{ height : 70, width : '100%'}}>
                        <Y>
                            <p style={{ ...getFontBebasNeue(), fontWeight : 600, fontSize : 22 }}>{props.title}</p>
                        </Y>
                    </div>
                    <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
                    <div style={{ height : 100, width : '100%'}}>
                        <GridRow height={100} style={{ marginTop : -10 }}>
                            <Button onClick={() => {
                                if(props.onClick) props.onClick( setReponse )
                            }}>
                                Submit
                            </Button>
                        </GridRow>
                    </div>
                    <LineDividerSpacer style={{ width : '100%', backgroundColor : 'rgba(0,0,0,0.075)'}}/>
                    <div style={{ minHeight : 70, width : '100%', paddingTop : 20 }}>
                        { response }
                    </div>
                </div>
            </div>
        </>
    )
}




const Button = ( props : { 
    children ?: any, 
    onClick ?: any 
}) => {
    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={props.onClick}>
            {props.children}
            </button>
        
        </>

    )
}

const QuickLogin = ( props : { 
    children ?: any 
}) => {
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                signInWithEmailAndPassword(auth,"newuser-test@email.com", "password").then(( res )=>{
                    alert("success!")
                    if( ENABLE_ERROR_LOGGING ) console.log(res)
                })
            }}>
            {props.children}
        </button>
        
        </>
    )
}


const QuickButton = ( props : { 
    children ?: any, 
    keyValue : string,
    params ?: any
    token?: string,
    endpoint?:string
}) => {

    const CONFIG = {
        headers: { 
            Authorization: `Bearer ${props.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },crossDomain: true
    };

    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                
                axios.post( props.endpoint ? props.endpoint : ENDPOINT_128 , {
                    key : props.keyValue ? props.keyValue : '',
                    params : props.params ? props.params : { testParam : -1 }
                }, CONFIG )
                .then((response) => {
                    if( ENABLE_ERROR_LOGGING ) console.log(response.data);
                })
                .catch(e => {
                    if( ENABLE_ERROR_LOGGING ) console.log('Error: ', e)
                });
                
    
            }}>
            {props.children}
        </button>
        
        </>

    )
}


function getTemporaryToken (){
    return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjU4ODI0YTI2ZjFlY2Q1NjEyN2U4OWY1YzkwYTg4MDYxMTJhYmU5OWMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vdGVzdC01MDAtOTk5OSIsImF1ZCI6InRlc3QtNTAwLTk5OTkiLCJhdXRoX3RpbWUiOjE2Nzg5MjAwNDUsInVzZXJfaWQiOiJ6OHVuN21lMmRBU0RDOFo5Q3o4VVhKNzRPc2IyIiwic3ViIjoiejh1bjdtZTJkQVNEQzhaOUN6OFVYSjc0T3NiMiIsImlhdCI6MTY3ODkyMDA0NSwiZXhwIjoxNjc4OTIzNjQ1LCJlbWFpbCI6InRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RhcGlnYXRld2F5QHRlc3RpbmcuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.VG7HrhFw5goK3q-hRGq_BK265VYIOC7eQ_tdJVx7yyGYqGojSGDr-Ug5WJdAGL3An9FxHnjS2G3ohl_UApEDWUx8YkD89ceglAr32fCvoc9PYPPsVPdFpjcJoYN2QEQavxkozT41uxZEO1GZp9Az8zjBMjtWL-6heI9G93I7E9owli0zltHFfUWpDUER9DINfniymr2PeeKWkpWz9cyQct_OigivokCmDfmJAhsW9gvuqA7Ku6I05NGK-OsjLkBSCgKwzzvHKCfIs5A0xUfN-mpbsFq0GiTD_mRmOWvYxz1kiiFX0V27MLGIjO8uRU5SYWhbG6kR_KfbXhqe2qmzQA'
}